import React, { useContext } from "react";
import { CommonDataContext } from "../../../contexts/CommonDataContext";
import PageOpportunityDetails_V2 from "./PageOpportunityDetails_V2";
import PageOpportunityDetails from "../PageOpportunityDetails";

const config = require("../../../config");

export const PageOpportunityDetailsContainer = ({ match }: any) => {
  const { isNewVoLayout } = useContext(CommonDataContext);

  return isNewVoLayout ? <PageOpportunityDetails_V2 match={match} /> : <PageOpportunityDetails match={match} />;
};
