import React from "react";
import { connect } from "react-redux";
import Table from "../../../../../components/Table";
import { setRefetch } from "../../../../../redux/actions";
import { getNested } from "../../../../../utils";

let contractDetailAPIKey = [
  {
    label: "Length Of Contract",
    apiKey: "lengthOfContractInMonths",
    format: "months",
    checkIfExists: true,
  },
  {
    label: "Extended Warrany",
    apiKey: "extendedWarrantyAmount",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Gap Insurance",
    apiKey: "gapInsuranceAmount",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Roadside Coverage",
    apiKey: "roadsideCoverageAmount",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Road Hazard",
    apiKey: "roadHazardAmount",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Doc Fee",
    apiKey: "docFeeAmount",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Physical Damage Coverage",
    apiKey: "physicalDamageCoverageAmount",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Federal Excise Tax",
    apiKey: "federalExciseTaxAmount",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Customer Rate",
    apiKey: "annualPercentageRate",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Monthly Payment",
    apiKey: "totalMonthlyPaymentEstimated",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Wholesale/Invoice",
    apiKey: "vehiclePriceWholesaleInvoice",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "MSRP/Retail",
    apiKey: "vehiclePriceMSRP",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Down Payment",
    apiKey: "downPaymentAmount",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Rebate",
    apiKey: "manufacturerRebateAmount",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Trade Allowance",
    apiKey: "tradeAllowanceGrossTradeIn",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Trade Owed",
    apiKey: "tradeOwedBalanceAmount",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Credit Life",
    apiKey: "creditLifePremiumAmount",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Disability",
    apiKey: "disabilityPremiumAmount",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Residual Percentage",
    apiKey: "msrpGuidePercentage",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Residual percentage",
    apiKey: "residualAmount",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Lease Rate Percentage",
    apiKey: "leaseRatePercentage",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Cust. Rate/Money Factor",
    apiKey: "leaseRateMoneyFactor",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Ins/SVC/Other",
    apiKey: "totalExtendedWarrantyAmount",
    format: "currency",
    checkIfExists: true,
  },
  {
    label: "Base Monthly Payment",
    apiKey: "baseMonthlyPayment",
    format: "currency",
    checkIfExists: true,
  },
];

interface Props {
  vo: any;
}

const ContractTable = ({ vo }: Props) => {
  const parseTableData = ({ label, apiKey, format, checkIfExists }: any) => {
    const rowData = getNested(vo, apiKey);
    if (checkIfExists && (!rowData || parseInt(rowData) === 0)) {
      return undefined;
    }

    if (rowData) {
      if (format === "upperCase") {
        let formattedString;
        if (rowData.includes("_")) {
          formattedString = vo[apiKey]
            .toLowerCase()
            .split("_")
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ");
        } else {
          formattedString = rowData
            .toLowerCase()
            .split(" ")
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ");
        }
        if (rowData.includes("delivered_pending_da")) {
          formattedString = "Delivered (Pending Acceptance)";
        }
        if (rowData.includes("da_complete")) {
          formattedString = "Customer Accepted";
        }
        return [label, formattedString];
      } else if (format === "months") {
        const formattedString = (
          <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>{rowData} months</div>
        );
        return [label, formattedString];
      } else if (format === "currency") {
        const formattedString = (
          <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
            {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(rowData)}
          </div>
        );
        return [label, formattedString];
      } else if (format === "date") {
        let inputDate = new Date(rowData + "T00:00:00");
        const getDay = inputDate.getDate();
        const getMonth = inputDate.getMonth() + 1; //Months are zero based
        const getYear = inputDate.getFullYear();
        const outputDate = getMonth + "-" + getDay + "-" + getYear;
        return [label, outputDate];
      } else {
        return [label, rowData];
      }
    } else {
      return [label, null];
    }
  };

  const contractTableData = contractDetailAPIKey.map(parseTableData).filter(i => i);
  contractTableData?.forEach(item => (item?.[0] ? (item[0] = <b>{item[0]}</b>) : item));

  return (
    <>
      {!!contractTableData?.length && (
        <Table tableHeaderColor="primary" colorsColls={["primary"]} tableData={contractTableData} striped={true} />
      )}
    </>
  );
};

const mapDispatchToProps = {
  setRefetch,
};

export default connect(null, mapDispatchToProps)(ContractTable);
