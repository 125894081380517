import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../../theme";
import {
  Button,
  CardActions,
  CardContent,
  Collapse,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  Modal,
  Typography,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { UPDATE_CREDIT_APPLICATION_V2_QUERY, UPLOAD_FILE, UPSERT_TASK } from "../queries";
import CreditSafeComp from "components/CreditSafeComp";
import EquipmentInputModal from "../../../../components/EquipmentInputModal";
import { GuarantorListWithCreditPull } from "components/GuarantorListWithCreditPull";
import { FINANCING_TYPE } from "@trnsact/trnsact-shared-types";

import { formatCurrency, OpportunityTypes } from "../../../../utils";
import _ from "lodash";
import { CardContainer } from "../../../../components/shared/CardContainer/CardContainer";
import { useMutation } from "@apollo/react-hooks";
import ConfirmationDialog from "components/ConfirmationDialog";
import { getSignatureTaskForContact } from "services/creditAppModalService";
import { ContactModal, RequestRecertificationModal } from "modules/creditApplicationDetailsActions";
import { ContentViewType } from "../../../../global";
import { ApplicantInfo } from "./ApplicantInfo";
import {
  ContactPayloadInput,
  ContactFormValues,
  RecertificationFormValues,
  ContactType,
} from "modules/creditApplicationDetailsActions/types";
import { iniitialRecertificationFormValues } from "modules/creditApplicationDetailsActions/constants";
import {
  getContactToAddGuaranteeFormValues,
  getContactToSave,
  getCreditAppInputForDocumentOrVerbalAuthorization,
  getRecertifiedCreditAppUploadFileInput,
  processFormValuesToPayload,
} from "modules/creditApplicationDetailsActions/lib";

interface Props {
  vo: any;
  refetchVO: any;
  vp: any;
  account: any;
  equipmentData: any;
  refetchEquipment: any;
  equipmentLoading: any;
  invoiceEquipmentTabPage: any;
  userProfile: any;
  refetchCreditApp: any;
  creditApplication: any;
  userDocs: any;
  userDocsRefetch: any;
  portalConfigurationDataLoading: any;
  isCreditReportsHiddenForUserRole: any;
  isIndividualCreditApp: boolean;
  tasksData: any;
  refetchDocs: any;
  contentViewType?: ContentViewType;
  goToCADView: VoidFunction;
}

export const CustomerSummaryCard_V2 = ({
  vo,
  refetchVO,
  vp,
  account,
  equipmentData,
  refetchEquipment,
  equipmentLoading,
  invoiceEquipmentTabPage,
  refetchCreditApp,
  creditApplication,
  userDocs,
  userDocsRefetch,
  portalConfigurationDataLoading,
  isCreditReportsHiddenForUserRole,
  isIndividualCreditApp,
  tasksData,
  refetchDocs,
  contentViewType = ContentViewType.Desktop,
  goToCADView,
}: Props) => {
  const APPLICANT_OPEN_STATE_KEY: string = "applicant";

  const classes = useStyles();
  const history = useHistory();

  const currentUserProfile = useSelector((state: any) => state.userProfile);

  const [doneRefetchingCreditApp, setDoneRefetchingCreditApp] = useState(false);
  const [doneRefetchingDocuments, setDoneRefetchingDocuments] = useState(false);
  const [documentIdToAutomaticallyOpen, setDocumentIdToAutomaticallyOpen] = useState(null);

  const [equipmentId, setEquipmentId] = useState("");
  const [openEquipmentInputModal, setOpenEquipmentInputModal] = useState(false);

  const [listItemOpenState, setListItemOpenState] = useState<any>({});

  // Add Contact Modal
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState<boolean>(false);
  const [addContactModalLoading, setAddContactModalLoading] = useState<boolean>(false);
  const [isAuthLinkConfirmationModalOpen, setIsAuthLinkConfirmationModalOpen] = useState<boolean>(false);
  const [contactToSave, setContactToSave] = useState<ContactPayloadInput | null>(null);

  // Guarantee Contact Modal
  const [isGuaranteeContactModalOpen, setIsGuaranteeContactModalOpen] = useState<boolean>(false);
  const [isGuaranteeContactModalLoading, setIsGuaranteeContactModalLoading] = useState<boolean>(false);
  const [contactToAddGuaranteeFormValues, setContactToAddGuaranteeFormValues] = useState<any>(null);

  // Request Recertification Modal
  const [isRequestRecertificationModalOpen, setIsRequestRecertificationModalOpen] = useState<boolean>(false);
  const [isRequestRecertificationModalLoading, setIsRequestRecertificationModalLoading] = useState<boolean>(false);
  const [recertificationFormValues, setRecertificationFormValues] = useState<any>(null);

  const applicantName = creditApplication?.businessName || vo.potentialCustomer?.name;

  const [updateCreditAppRequest] = useMutation(UPDATE_CREDIT_APPLICATION_V2_QUERY, {
    context: { authRequired: true },
  });

  const [upsertTask] = useMutation(UPSERT_TASK, {
    context: { authRequired: true },
  });

  const [uploadFile, { loading }] = useMutation(UPLOAD_FILE);

  useEffect(() => {
    if (documentIdToAutomaticallyOpen && doneRefetchingDocuments) {
      setDoneRefetchingDocuments(false);
      if (documentIdToAutomaticallyOpen) {
        console.log(JSON.stringify(userDocs));
        const doc = userDocs.documents.find((d: any) => d.documentId === documentIdToAutomaticallyOpen);
        console.log(JSON.stringify(doc));
        if (doc) {
          // TODO: This if statement is reached but window.open() is not working. Popup blocker?
          window.open(doc.source, "_newtab");
        }
        return;
      }
      setDocumentIdToAutomaticallyOpen(null);
    }
  });

  const handleOpenCreditReportDocument = (pgDetails: any, cbr: any) => {
    const reportDoc = userDocs.documents.find((d: any) => d.documentId === cbr.humanReadableReportDocumentId);
    if (reportDoc) {
      window.open(reportDoc.source, "_newtab");
    }
  };

  const openEquipmentInputModalFn = (equipmentId: string) => {
    setOpenEquipmentInputModal(true);
    setEquipmentId(equipmentId);
  };

  const handleViewDetailsClick = () => {
    goToCADView();
  };

  const handleGuaranteeClick = (contact: any) => {
    const contactToAddGuaranteeFormValues: ContactFormValues = getContactToAddGuaranteeFormValues(contact);
    setContactToSave(contact);
    setContactToAddGuaranteeFormValues(contactToAddGuaranteeFormValues);
    setIsGuaranteeContactModalOpen(true);
  };

  const handleRecertifyClick = (contact: any) => {
    setIsRequestRecertificationModalOpen(true);
    const recertificationFormValues: RecertificationFormValues = {
      ...iniitialRecertificationFormValues,
      signatureDate: contact.signatureDate ? new Date(+contact.signatureDate).toString() : new Date().toString(),
    };
    setRecertificationFormValues(recertificationFormValues);
    setContactToSave(contact);
  };

  const handleSaveGuaranteeContactModal = async (contactFormValues: ContactFormValues) => {
    const initialValues = {
      ownerPgId: contactToSave?.ownerPgId,
      isOwnerPg: true,
    };
    const updatedContactToSave: ContactPayloadInput = processFormValuesToPayload(contactFormValues, initialValues);
    setIsGuaranteeContactModalLoading(true);
    await hanldeSavingContact(updatedContactToSave);
    await refetchCreditApp();
    setIsGuaranteeContactModalOpen(false);
    setIsGuaranteeContactModalLoading(false);
    setContactToSave(null);
  };

  const handleSaveAddContactModal = async (contactFormValues: ContactFormValues, contactType: ContactType) => {
    const contactToSave: ContactPayloadInput = getContactToSave(creditApplication, contactFormValues, contactType);
    setContactToSave(contactToSave);
    if (contactType === "contact-only") {
      setAddContactModalLoading(true);
      await hanldeSavingContact(contactToSave);
      await refetchCreditApp();
      setIsAddContactModalOpen(false);
      setAddContactModalLoading(false);
      setContactToSave(null);
      return;
    }
    setIsAuthLinkConfirmationModalOpen(true);
  };

  const handleSaveRequestRecertificationModal = async (recertificationFormValues: RecertificationFormValues) => {
    const handleDocumentOrVerbalAuthorization = async () => {
      const creditAppInput = getCreditAppInputForDocumentOrVerbalAuthorization(
        creditApplication,
        contactToSave,
        recertificationFormValues,
        currentUserProfile
      );
      await updateCreditAppRequest({
        variables: {
          creditAppId: creditApplication.id,
          input: creditAppInput,
        },
      });
      if (recertificationFormValues.file) {
        await uploadFile({
          variables: {
            input: getRecertifiedCreditAppUploadFileInput(vo),
            file: recertificationFormValues.file,
          },
        });
      }
    };

    const handleTaskCreationAuthorization = async () => {
      const taskToCreate = getSignatureTaskForContact(
        contactToSave,
        vo.vendorOpportunityId,
        creditApplication.dynamicsId,
        account,
        currentUserProfile
      );
      await upsertTask({
        variables: {
          input: taskToCreate,
        },
      });
    };

    setIsRequestRecertificationModalLoading(true);
    recertificationFormValues.recertificationOption === "send-task"
      ? await handleTaskCreationAuthorization()
      : await handleDocumentOrVerbalAuthorization();
    await refetchCreditApp();
    setIsRequestRecertificationModalOpen(false);
    setIsRequestRecertificationModalLoading(false);
    setContactToSave(null);
  };

  const hanldeSavingContact = async (contactToSave: ContactPayloadInput | null) => {
    if (!contactToSave) {
      return;
    }
    await updateCreditAppRequest({
      variables: {
        creditAppId: creditApplication.id,
        input: {
          owners: [contactToSave],
        },
      },
    });
  };

  const onAuthLinkConfirmationModalClose = async (event: any, reason: string) => {
    if (_.includes(["backdropClick", "escapeKeyDown"], reason)) {
      return;
    }
    setIsAuthLinkConfirmationModalOpen(false);
    if (event.result === "ok") {
      setAddContactModalLoading(true);
      await hanldeSavingContact(contactToSave);
      const refetchedCreditAppData = await refetchCreditApp();
      const savedContact = _.find(refetchedCreditAppData?.data?.creditApplication?.creditApplicationOwner, {
        email: contactToSave?.email,
        ownerNumber: contactToSave?.ownerNumber,
      });
      const taskToCreate = getSignatureTaskForContact(
        savedContact,
        vo.vendorOpportunityId,
        creditApplication.dynamicsId,
        account,
        currentUserProfile
      );
      await upsertTask({
        variables: {
          input: taskToCreate,
        },
      });
      //await refetchTasks();
      setIsAddContactModalOpen(false);
      setAddContactModalLoading(false);
      setContactToSave(null);
    }
  };

  const handleCloseEquipmentInputModal = () => setOpenEquipmentInputModal(false);

  const handleCloseAddContactModal = () => {
    setIsAddContactModalOpen(false);
  };

  const handleCloseGuaranteeContactModal = () => {
    setIsGuaranteeContactModalOpen(false);
  };

  const handleCloseRequestRecertificationModal = () => {
    setIsRequestRecertificationModalOpen(false);
  };

  // hotfix: a workaround to be able to associate owner with appropriate result from GuarantorListWithCreditPull
  const guarantorListWithCreditPull: any = GuarantorListWithCreditPull({
    vo,
    creditApp: creditApplication,
    vp,
    refetchCreditApp,
    handleOpenCreditReportDocument,
    isCreditReportsHiddenForUserRole,
  });
  const creditPullComponentByOwnerId = _.keyBy(guarantorListWithCreditPull, item => {
    if (_.isArray(item)) {
      return _.get(item, "[0].props.data-owner-pg-id");
    }
  });

  const getApplicantLayout = () => {
    if (_.isEmpty(vo) || _.isEmpty(creditApplication)) {
      return;
    }

    const ownersToShow = _([...[creditApplication.primaryContact], ...creditApplication.creditApplicationOwner])
      .compact()
      .uniqBy("ownerPgId")
      .value();

    return (
      <>
        <ListItem
          button
          onClick={() => {
            setListItemOpenState((prev: any) => {
              return {
                ...prev,
                [APPLICANT_OPEN_STATE_KEY]: !prev[APPLICANT_OPEN_STATE_KEY],
              };
            });
          }}
          key={"applicant"}
        >
          <Grid container>
            <Grid item xs={6} style={{ display: "flex" }}>
              <RowNameText text={"Applicant"} />
              {listItemOpenState[APPLICANT_OPEN_STATE_KEY] ? <ExpandLess /> : <ExpandMore />}
            </Grid>
            <Grid item xs={6} style={{ display: "flex" }}>
              <PermContactCalendarIcon fontSize="small" style={{ color: "#4994ec", marginRight: 8 }} />

              <Typography
                variant="body1"
                className={classes.link}
                onClick={() => {
                  history.push(`/accountDetails/${vo.potentialCustomer.id}`);
                }}
              >
                {applicantName}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>

        <Divider />

        <Collapse in={listItemOpenState[APPLICANT_OPEN_STATE_KEY]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {ownersToShow.map(owner => {
              const phoneToDisplay = owner.phone || owner.mobilePhone || owner.homePhone || owner.workPhone;

              return (
                <ApplicantInfo
                  owner={owner}
                  key={owner.ownerPgId}
                  phoneToDisplay={phoneToDisplay}
                  contentViewType={contentViewType}
                  creditPullComponentByOwnerId={creditPullComponentByOwnerId}
                  handleGuaranteeClick={handleGuaranteeClick}
                  handleRecertifyClick={handleRecertifyClick}
                />
              );
            })}
          </List>
        </Collapse>
      </>
    );
  };

  const getEquipmentDescription = () => {
    return equipmentData && _.some(equipmentData.equipments)
      ? _.map(equipmentData.equipments, item => {
          return (
            <a
              href="#"
              onClick={() => openEquipmentInputModalFn(item.equipmentId)}
              style={{ display: "inline-block", color: theme.palette.primary.main }}
              key={`${item.equipmentId}${item.stockId}${item.make}${item.model}`}
            >
              {(item.quantity ? "Qty " + item.quantity : "") +
                (item.newUsed ? ", " + item.newUsed : "") +
                (item.make ? ", " + item.make : "") +
                (item.model ? ", " + item.model : "") +
                (item.serialNumberVin ? ", SN/VIN#" + item.serialNumberVin : "") +
                (item.additionalDescription ? ", " + item.additionalDescription : "") +
                (item.stockId ? ", " + item.stockId : "")}
            </a>
          );
        })
      : _.get(vo, "equipmentDescription");
  };

  const equipmentDescription = getEquipmentDescription();
  const isLoading = !creditApplication || !vo || portalConfigurationDataLoading;
  return (
    <>
      <CardContainer
        title="Customer Summary"
        contentViewType={contentViewType}
        actionBtnProps={{
          label: "Credit Application Detail",
          onClick: handleViewDetailsClick,
        }}
      >
        {!isLoading && (
          <Typography className={classes.cardHeaderDateOfApplication}>
            Date of Application: {creditApplication.createdDateTime}
          </Typography>
        )}
        <CardContent style={{ padding: 0 }}>
          {isLoading ? (
            <LinearProgress style={{ width: "100%" }} />
          ) : (
            <List component="nav" aria-labelledby="nested-list-subheader">
              {getApplicantLayout()}
              {!isIndividualCreditApp &&
                _.get(vp, "settingBusinessCreditReportPullInDealerPortal") === "PAYNET" &&
                !isCreditReportsHiddenForUserRole && (
                  <>
                    <ListItem>
                      <Grid container>
                        <Grid item xs={6}>
                          <RowNameText text={"Paynet Report"} />
                        </Grid>
                        <Grid item xs={6}>
                          <CreditSafeComp
                            vo={vo}
                            creditApp={creditApplication}
                            userDocs={userDocs}
                            openCreditPullErrorSnackBar={() => {
                              alert("Credit Pull Error");
                            }}
                            setCreditPullErrorSnackBarText={() => {
                              console.log("Credit Pull Error");
                            }}
                            userDocsRefetch={userDocsRefetch}
                            refetchCreditApp={refetchCreditApp}
                            CbrSoftPullTooltip={<div>Contact Support</div>}
                            doneRefetchingCreditApp={doneRefetchingCreditApp}
                            setDoneRefetchingDocuments={setDoneRefetchingDocuments}
                            setDocumentIdToAutomaticallyOpen={setDocumentIdToAutomaticallyOpen}
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Divider />
                  </>
                )}
              <ListItem>
                <Grid container>
                  <Grid item xs={6}>
                    <RowNameText text={"Applicant Type"} />
                  </Grid>
                  <Grid item xs={6}>
                    <RowValueText text={isIndividualCreditApp ? "Individual" : "Commercial"} />
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
              {creditApplication.applyingFor && (
                <>
                  <ListItem>
                    <Grid container>
                      <Grid item xs={6}>
                        <RowNameText text={"Applying For"} />
                      </Grid>
                      <Grid item xs={6}>
                        <RowValueText
                          text={_(creditApplication.applyingFor)
                            .map((item: string) => FINANCING_TYPE[item as keyof typeof FINANCING_TYPE] || item)
                            .join(", ")}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </>
              )}
              <ListItem>
                <Grid container>
                  <Grid item xs={6}>
                    <RowNameText text={"Type"} />
                  </Grid>
                  <Grid item xs={6}>
                    <RowValueText text={OpportunityTypes[vo.entityType as keyof typeof OpportunityTypes]} />
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
              <ListItem>
                <Grid container>
                  <Grid item xs={6}>
                    <RowNameText text={"Amount"} />
                  </Grid>
                  <Grid item xs={6}>
                    <RowValueText
                      text={vo.invoiceTotal || vo.invoiceTotal === 0 ? formatCurrency(vo.invoiceTotal) : "-"}
                    />
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
              <ListItem>
                <Grid container>
                  <Grid item xs={6}>
                    <RowNameText text={"Equipment Description"} />
                  </Grid>
                  <Grid item xs={6}>
                    <RowValueText text={equipmentDescription || "-"} />
                  </Grid>
                </Grid>
              </ListItem>
              {vo.contactFormNotes && (
                <>
                  <Divider />
                  <ListItem>
                    <Grid container>
                      <Grid item xs={6}>
                        <RowNameText text={"Message to Dealer"} />
                      </Grid>
                      <Grid item xs={6}>
                        <RowValueText text={vo.contactFormNotes} />
                      </Grid>
                    </Grid>
                  </ListItem>
                </>
              )}
              {vo.partnerName && (
                <>
                  <Divider />
                  <ListItem>
                    <Grid container>
                      <Grid item xs={6}>
                        <RowNameText text={"Source"} />
                      </Grid>
                      <Grid item xs={6}>
                        <RowValueText text={vo.partnerName} />
                        {vo.sourceUrl && (
                          <>
                            <br />
                            <a href={vo.sourceUrl} target="_blank" rel="noreferrer">
                              Source URL
                            </a>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </ListItem>
                </>
              )}
            </List>
          )}
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => setIsAddContactModalOpen(true)}
            >
              Add Contact
            </Button>
          </CardActions>
        </CardContent>
      </CardContainer>

      <Modal
        open={openEquipmentInputModal}
        onClose={handleCloseEquipmentInputModal}
        aria-labelledby="Equipment Input Modal"
        aria-describedby="Input"
      >
        <div>
          {vp && equipmentId && (
            <EquipmentInputModal
              vo={vo}
              creditApplication={creditApplication}
              equipmentLoading={equipmentLoading}
              equipmentData={equipmentData}
              handleClose={handleCloseEquipmentInputModal}
              refetchVO={refetchVO}
              equipmentId={equipmentId}
              refetch={refetchEquipment}
              vendorProfile={vp}
              refetchCreditApp={refetchCreditApp}
              invoiceEquipmentTabPage={invoiceEquipmentTabPage}
              refetchDocs={refetchDocs}
              account={account}
            />
          )}
        </div>
      </Modal>

      <ContactModal
        isOpen={isAddContactModalOpen}
        onClose={handleCloseAddContactModal}
        onSave={handleSaveAddContactModal}
        isLoading={addContactModalLoading}
        mode="add-contact"
      />

      <ContactModal
        isOpen={isGuaranteeContactModalOpen}
        onClose={handleCloseGuaranteeContactModal}
        onSave={handleSaveGuaranteeContactModal}
        isLoading={isGuaranteeContactModalLoading}
        mode="guarantee"
        defaultContactType="signor-and-guarantor"
        defaultFormValues={contactToAddGuaranteeFormValues}
      />

      <ConfirmationDialog
        title="Confirmation"
        message="This action will create a task to gather additional information"
        loading={false}
        open={isAuthLinkConfirmationModalOpen}
        okButtonText="Confirm"
        cancelButtonText="Cancel"
        onClose={onAuthLinkConfirmationModalClose}
      />

      <RequestRecertificationModal
        isOpen={isRequestRecertificationModalOpen}
        onClose={handleCloseRequestRecertificationModal}
        onSave={handleSaveRequestRecertificationModal}
        isLoading={isRequestRecertificationModalLoading}
        applicantName={applicantName}
        contact={contactToSave}
        vendorOpportunityTasks={tasksData?.vendorOpportunityTask}
        defaultFormValues={recertificationFormValues}
      />
    </>
  );
};

const RowNameText = ({ text }: { text: string }) => {
  const classes = useStyles();
  return <Typography className={classes.listRowName}>{text || ""}</Typography>;
};

const RowValueText = ({ text, typographyProps }: { text: string; typographyProps?: any }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.listRowValue} {...typographyProps}>
      {text || ""}
    </Typography>
  );
};

const useStyles = makeStyles(theme => ({
  link: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  listRowName: {
    color: "#666666",
    fontWeight: 500,
  },
  listRowValue: {
    color: "#212121",
  },
  card: {
    margin: 0,
    height: "100%",
    width: "100%",

    "& .MuiCardHeader-action": {
      margin: 0,
    },
    "& .MuiCardContent-root": {
      paddingTop: 0,
    },
  },
  cardHeader: {
    margin: "10px 10px 0 10px",
  },
  cardHeaderDateOfApplication: {
    display: "flex",
    justifyContent: "flex-end",
    color: "#666666",
  },
}));
